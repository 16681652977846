import type { QueryFilters, QueryKey } from "@tanstack/vue-query";

import type { MutationMap } from "./types";

export function useCacheOptimisticUpdateFiltered<Vars, Data>(
  optimisticUpdatePlan: (vars: Vars, draft: Data, key: QueryKey) => void
) {
  const { $logger } = useNuxtApp();
  const queryClient = useQueryClient();

  const onMutate = async (vars: Vars, filters: QueryFilters[], mutationMap: MutationMap = []) => {
    const _mutationMap: MutationMap = [...mutationMap];
    // iterate through all the cache keys and update the cache
    for (const filter of filters) {
      $logger.debug("useCacheOptimisticUpdate: onMutate: filter", filter);
      for (const [key, previousData] of queryClient.getQueriesData<Data>(filter)) {
        if (!previousData) continue; // Nodata found so nothing to  update
        await queryClient.cancelQueries({ queryKey: key });
        const newData = structuredClone(previousData);
        optimisticUpdatePlan(vars, newData, key);

        queryClient.setQueryData(key, newData);
        _mutationMap.push({ key, previousData });
      }
    }
    return _mutationMap;
  };

  return {
    onMutate
  };
}

export default function useCacheOptimisticUpdate<Vars, Data>(
  optimisticUpdatePlan: (vars: Vars, draft: Data, key: QueryKey) => void
) {
  const composableResult = useCacheOptimisticUpdateFiltered(optimisticUpdatePlan);

  const onMutate = (vars: Vars, affectedCacheKeys: QueryKey[], mutationMap: MutationMap = []) => {
    const filters = affectedCacheKeys.map<QueryFilters>((queryKey) => ({ queryKey, exact: true }));
    return composableResult.onMutate(vars, filters, mutationMap);
  };

  return { ...composableResult, onMutate };
}
